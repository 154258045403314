import React, { Component } from 'react';
import '../App.css';
import video from '../assets/Metrix.m4v';

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

const mem1 = () => {
  window.open("https://twitter.com/Shanegarland11");
}


const mem2 = () => {
  window.open("https://twitter.com/CTMEHS");
}


const mem3 = () => {
  window.open("https://twitter.com/aaronschaar_GG");
}


class Video extends Component {



  async componentDidMount() {


  }

  render() {
    return (
      <div class="boxWrap2Light">

        <div class="vidCont4">
          <video class="videoBg4" src={video} autobuffer autoPlay loop muted playsInline></video></div>

      </div>
    )
  }
}

export default Video;