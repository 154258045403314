import React, { Component } from 'react';
import twitter from '../assets/twitter.png';
import discord from '../assets/discord.png';
import tiktok from '../assets/tik-tok.png';
import vimeo from '../assets/vimeo.png';
import insta from '../assets/instagram.png';
import reddit from '../assets/reddit.png';

const tweet = () => {
	window.open("https://twitter.com/worlddataminer");
  }
    
  const tikL = () => {
	window.open("https://www.tiktok.com/@worlddataminer");
  }
    
  const instagram = () => {
	window.open("https://www.instagram.com/worlddataminer/");
  }

  const discrd = () => {
	window.open("https://discord.gg/cab2s8VW5A");
  }

  const vimeoL = () => {
	window.open("https://vimeo.com/channels/worlddataminer");
  }

  const redditL = () => {
	window.open("https://www.reddit.com/r/WorldDataMiner/");
  }


class Footer extends Component {

	render() {

		return (

			<div class="footer">

				<div class="right2">

					<div class="socialFDiv"><img class="socialF" onClick={tweet} src={twitter}/></div>
					<div class="socialFDiv"><img class="socialF" onClick={tikL} src={tiktok}/></div>
					<div class="socialFDiv"><img class="socialF" onClick={instagram} src={insta}/></div>
					<div class="socialFDiv"><img class="socialF" onClick={discrd} src={discord}/></div>
					<div class="socialFDiv"><img class="socialF" onClick={vimeoL} src={vimeo}/></div>
					<div class="socialFDiv"><img class="socialF" onClick={redditL} src={reddit}/></div>

				</div>
				<div class="logoF">World Data Miners</div>
				<div class="copyright">2022 WORLD DATA Miners ALL RIGHTS RESERVED.</div>

			</div>)
	}
}

export default Footer;