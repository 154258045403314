import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (
            <div class="storyMain">
                <div class="boxWrap2" data-aos="fade-up" data-aos-duration="1500">


                    <div class="teamH" >Welcome to The World Data Miners NFT Marketplace</div>
                    <div class="boxWrap2-2">

                        <div class="storyRight">
                            <p class="storyCon">The WDM NFT Collection consist of 10,000 unique, hand drawn Miners from across space and time, spanning Earth years 1977 to 3077. They are immortalized forever on the Ethereum Blockchain.</p>

                            <p class="storyCon2-2">And they come with a warning:</p>

                            <div class="quotes" >
                                <p>“The Technological Elites now exploit the entire universe, not just your Earth and Metaverse. In 3075, Humans and Humaliens, joined by Psyborg Rebels, rose up against the cruel ruling class, hoping to free all sentient beings from the grasp of our oppressors. A long brutal fight lies ahead.</p>
                                <p>Our struggle will be in vain without help from the Humans of your time. Please consider becoming a member of WorldDataMiner.com. Join our community as we set sail for The Black, on the most hazardous, and dangerous, and greatest adventure, of which humankind has ever embarked.”</p>
                            </div>

                            <p></p>
                            <p class="storyCon">If you don’t know the gruesome history of humanity, post Earth, find the details at worlddataminer.com</p>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default Story;

