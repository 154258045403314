import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import video from '../assets/Mining.m4v';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class About extends Component {

    render() {
        return (

            <div class="boxWrap5">
                <div class="vidCont"><video class="videoBg2" src={video} autoPlay playsInline loop muted></video></div>

                <div class="storyRight2" data-aos="fade-in" data-aos-duration="1500">
                    <div class="aboutT">ABOUT THE PROJECT</div>
                    <p class="storyCon2">World Data Miner is a data monetization organization managed by our NFT holders. We help our Members build passive, perpetual revenue streams using the data already being collected on us, every moment of our digital lives. Membership is free, sign up today.</p>
                    <p class="storyCon2">The WDM NFT collection is made up equally of Humans, Humaliens, and Psyborgs. Some may even have more DNA, circuits and wires crossed than that. They have 75 different traits shared among them, mostly different helmets, eye protection, and mining tools. Out in The Black you must use what you can find to get the job done.</p>
                    <p class="storyCon2">These one-of-a-kind NFTs grant the holder a unique experience in the NFT world.</p>
                    <p class="storyCon2">Our goal is to create the largest data monetization community on the internet. We guide our members step by step so they can start taking control of their data and begin generating income with it.  </p>
                    <p class="storyCon2">The pre-sale floor price for each unique WDM NFT is .039 ETH.</p>
                    <p class="storyCon2">The are 1,000 available for presale.</p>
                    <p class="storyCon2">Public sale floor price is .069 ETH.</p>
                    <p class="storyCon2">*ATTENTION: No Cyborgs/Bots built before 2077 are allowed to own or sell WDM NFTs. Any found must be destroyed. </p>
                </div>

            </div>
        )
    }
}

export default About;

