import React, { Component } from 'react';
import '../App.css';
import coin from '../assets/coin.png';

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

const mem1 = () => {
  window.open("https://twitter.com/Shanegarland11");
}


const mem2 = () => {
  window.open("https://twitter.com/CTMEHS");
}


const mem3 = () => {
  window.open("https://twitter.com/aaronschaar_GG");
}


class Team extends Component {



  async componentDidMount() {


  }

  render() {
    return (
      <div class="boxWrap2Light">

        <div class="aboutT">BENEFITS</div>

        <div class="boxWrap2-3">
          <div class="section1Li" data-aos="zoom-in" data-aos-duration="1500">
            <div class="storyCon2-3"><p>NFT Owner Benefits:</p></div>

            <div class="giveaways">
              <div class="section1give">
                <p><div class="picAndnote">
                  <div class="gaPicDiv"><img class="gaPic" src={coin} /></div>
                  <div class="noteGiveaway">Exclusive ability to launch their own data pools</div>
                </div>
                </p>
                <p><div class="picAndnote">
                  <div class="gaPicDiv"><img class="gaPic" src={coin} /></div>
                  <div class="noteGiveaway">Exclusive eligibility to hold board and committee seats</div>
                </div>
                </p>

                <p><div class="picAndnote">
                  <div class="gaPicDiv"><img class="gaPic" src={coin} /></div>
                  <div class="noteGiveaway">Exclusive project proposal rights</div>
                </div>
                </p>

                <p><div class="picAndnote">
                  <div class="gaPicDiv"><img class="gaPic" src={coin} /></div>
                  <div class="noteGiveaway">Ability to launch WDM community projects on WDM platforms</div>
                </div>
                </p>

                <p><div class="picAndnote">
                  <div class="gaPicDiv"><img class="gaPic" src={coin} /></div>
                  <div class="noteGiveaway">Community management—all duties of the organization will eventually be carried-out by NFT holders and voted on by all WDM token holders</div>
                </div>
                </p>

                <p><div class="picAndnote">
                  <div class="gaPicDiv"><img class="gaPic" src={coin} /></div>
                  <div class="noteGiveaway">Our NFT holders also earn a higher percentage of fees generated across all datapools they participate in</div>
                </div>
                </p>

                <p><div class="picAndnote">
                  <div class="gaPicDiv"><img class="gaPic" src={coin} /></div>
                  <div class="noteGiveaway">Our NFT holders earn more tokens per data upload than non-NFT holders while participating in our data markets</div>
                </div>
                </p>


              </div>


            </div>

          </div>

          <div class="section1Li" data-aos="zoom-in" data-aos-duration="1500">
            <div class="storyCon2-3" ><p>WDM NFT holders also enjoy the benefits of regular World Data Miner members and token holders:</p></div>

            <div class="giveaways">
              <div class="section1give">
                <p><div class="picAndnote">
                  <div class="gaPicDiv"><img class="gaPic" src={coin} /></div>
                  <div class="noteGiveaway">The first verified data set a Member uploads to our secure and  encrypted servers, they will earn WDM tokens (NFT holders earn a higher amount)</div>
                </div>
                </p>

                <p><div class="picAndnote">
                  <div class="gaPicDiv"><img class="gaPic" src={coin} /></div>
                  <div class="noteGiveaway">Every time a Member uploads a data set, or refreshes a dataset they already have, they earn more WDM tokens</div>
                </div>
                </p>

                <p><div class="picAndnote">
                  <div class="gaPicDiv"><img class="gaPic" src={coin} /></div>
                  <div class="noteGiveaway">Share of fees generated on activity based on the WDM tokens kept as liquidity in our data pools (NFT holders earn higher rates)</div>
                </div>
                </p>

                <p><div class="picAndnote">
                  <div class="gaPicDiv"><img class="gaPic" src={coin} /></div>
                  <div class="noteGiveaway">All the revenue generated from data, minus small pool fees, goes directly back to the data miners who provided their data.</div>
                </div>
                </p>

              </div>

            </div>

          </div>


        </div>
        <div class="underBenefits">
        <p class="storyCon2">There will be contest, prizes, trivia and more in our Discord, TikTok, Instagram and other social media accounts, and participation or rewards will depend on certain attributes of your NFT.</p>
        <p class="storyCon2">All of our information, updates, flightpath, docs and details can be found on our official website at www.WorldDataMiner.com</p>
        <p class="storyCon2">World Data Miner is a long term project that is just getting started. Please join our mission, and share this with your friends!</p>
        </div>
      </div>
    )
  }
}

export default Team;