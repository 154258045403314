import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const discord = () => {
    window.open("https://discord.gg/muQDE3xeKE");
}

class Community extends Component {

    render() {
        return (

            <div class="boxWrap8">

                <div class="com"  data-aos="fade-up" data-aos-duration="1500">
                    <p class="cP">
                        <div class="cT">World Data Miners is a long term project that is just getting started.</div>
                        <p class="cT2">To get your free World Data Miner account and to learn more about our community please visit <span class="aLink"><a href="http://www.worlddataminer.com/" target="_blank">www.worlddataminer.com</a></span></p>
                        <div class="cT2">Please share this with your friends!</div>

                    </p>

                </div>

            </div>
        )
    }
}

export default Community;

